
<template>
<v-container>

<v-row>
    <v-card elevation="2" outlined shaped class = "mx-2">
        <v-col>
                  <v-data-table
                    class = "hidden-sm-and-down"
                    dense
                    :headers = "headers"
                    :items = "years_aa"
                    :items-per-page = "22"
                  > </v-data-table>
                  <!------------------------------------------------------------>
                  <v-simple-table 
                    class = "hidden-md-and-up"
                    dense
                    >
                    <template v-slot:default>

                      <thead>
                            <tr>
                                <th><i> starts  </i></th>
                                <th><i> year of </i></th>
                                <th><i> element </i></th>
                                <th>    ☯           </th>
                            </tr> 
                    </thead>

                      <tbody>
                              <tr v-for = "yr_st, kk in years_aa">
                                  <td> {{ yr_st.YR            }} </td>
                                  <td> {{ yr_st.Animal        }} </td>
                                  <td> {{ yr_st.Element_Long  }} </td>
                                  <td> {{ yr_st.Yin_Yang      }} </td>
                              </tr>
                      </tbody>
                      </template>
                  </v-simple-table>

      </v-col>  
  </v-card>

</v-row>  

</v-container>
</template>

<!------------------------------------------------------------>
<script>

import       chinese_json     from './chinese.json'     ;

export default {
                    data: () => ({
                                    years_aa: [],

                                    headers: [
                                                { text: 'starts',  value: 'YR', align: 'start',  sortable: false,   },
                                                { text: 'year_of', value: 'Animal_Long'   },
                                                { text: 'element', value: 'Element_Long'  },
                                                { text: '☯',       value:'Yin_Yang' },
                                              ],
                                  }),

// mixins: [ get_Chinese_Animal],

methods: { 
            animals_Handler() {
                                  let cc = 0 ;
                                  let x_Animal_Raw  = '' ;
                                  let x_Animal      = '' ;
                                  let x_Animal_Long = '' ;
                                  let x_Animal_3x   = '' ;

                                  let x_Element_Raw  = '' ;
                                  let x_Element_Long = '' ;

                                  let x_Yin_Yang_Raw  = '' ;
                                  let x_Yin_Yang_Long = '' ;
                                  let x_Yin_Yang      = '' ;

                                  let                  total_nbr_years = this.years_aa.length ;
                                  for (  cc = 0 ; cc < total_nbr_years ; cc++) {
                                                                                       x_Animal_Raw = this.years_aa[cc].ANIMAL ;


                                                                         x_Animal_3x = x_Animal_Raw.substring(0,3) ;


                                                                         
                                                                  switch(x_Animal_3x) {
                                                                                        case 'Rat': x_Animal_Long = '🐀 Rat'     ; x_Animal = '🐀' ;  break ;    
                                                                                        case 'Ox' : x_Animal_Long = '🐮 Ox'      ; x_Animal = '🐮' ; break ;    
                                                                                        case 'Tig': x_Animal_Long = '🐯 Tiger'   ; x_Animal = '🐯' ; break ;    
                                                                                        case 'Rab': x_Animal_Long = '🐰 Rabbit'  ; x_Animal = '🐰'  ; break ;    
                                                                                        case 'Dra': x_Animal_Long = '🐲 Dragon'  ; x_Animal = '🐲' ; break ;    
                                                                                        case 'Sna': x_Animal_Long = '🐍 Snake'   ; x_Animal = '🐍' ; break ;    
                                                                                        case 'Hor': x_Animal_Long = '🐴 Horse'   ; x_Animal = '🐴' ; break ;    
                                                                                        case 'Goa': x_Animal_Long = '🐐 Goat'    ; x_Animal = '🐐' ; break ;    
                                                                                        case 'Mon': x_Animal_Long = '🐵 Monkey'  ; x_Animal = '🐵' ; break ;    
                                                                                        case 'Roo': x_Animal_Long = '🐔 Rooster' ; x_Animal = '🐔' ; break ;    
                                                                                        case 'Dog': x_Animal_Long = '🐶 Dog'     ; x_Animal = '🐶' ; break ;    
                                                                                        case 'Pig': x_Animal_Long = '🐷 Pig'     ; x_Animal = '🐷' ; break ;    
                                                                                        default   : x_Animal_Long = ''           ; x_Animal = ''    ; break ;    
                                                                                       }

                                                                                     x_Element_Raw = this.years_aa[cc].ELEMENT ;
                                                                              switch(x_Element_Raw) {
                                                                                                      case 'Wood'  : x_Element_Long = '🥢wood'   ; break ;    
                                                                                                      case 'Water' : x_Element_Long = '🌊water'  ; break ;    
                                                                                                      case 'Metal' : x_Element_Long = '⚓metal'  ; break ;    
                                                                                                      case 'Earth' : x_Element_Long = '🌎earth'  ; break ;    
                                                                                                      case 'Fire'  : x_Element_Long = '🔥fire'   ; break ;    
                                                                                                      default      : x_Element_Long = ''         ; break ;    

}
                                                                                     x_Yin_Yang_Raw = this.years_aa[cc].YY ;
                                                                              switch(x_Yin_Yang_Raw) {
                                                                                                      case 'Yin'   : x_Yin_Yang_Long = '♀️ female' ; x_Yin_Yang = '♀️' ; break ;    
                                                                                                      case 'Yang'  : x_Yin_Yang_Long = '♂️ male'   ; x_Yin_Yang = '♂️' ;  break ;    
                                                                                                      default      : x_Yin_Yang_Long = ''         ; x_Yin_Yang = '.' ;  break ;    
                                                                                                    }
                                      this.years_aa[cc].Animal_Long   = x_Animal_Long ;                        
                                      this.years_aa[cc].Animal        = x_Animal      ;                        

                                      this.years_aa[cc].Element_Long  = x_Element_Long ;                        

                                      this.years_aa[cc].Yin_Yang_Long = x_Yin_Yang_Long ;                        
                                      this.years_aa[cc].Yin_Yang      = x_Yin_Yang      ;                        
                                  } 


                                  } 
},

created() {
                                  let  years_aa = [] ;
                                  this.years_aa = Object.assign( years_aa, chinese_json) ;

                                  this.animals_Handler() ;
          },

                      //____________________________________________________________________________
  
  }

</script>

<!-------------------------------------------->
<style>

th, td {
         text-align: center; 
}

</style>
